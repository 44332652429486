import {gql} from "@apollo/client";

const LEARNER_INFO = gql`
    fragment LearnerInfo on Learner {
        id
        firstName
        lastName
        birthday
        description
        learner
        email
        phone
    }
`

export const GET_LEARNERS = gql`    
    query Learners {
        learners {
            ...LearnerInfo
        }
    }
    ${LEARNER_INFO}
`

export const ADD_LEARNER = gql`    
    mutation AddLearners ($email: String, $name: String){
        createLearner(learner: {learner: $name, email: $email}){
            ...LearnerInfo
        }
    }
    ${LEARNER_INFO}
`


export const SEND_LINK = gql`    
    mutation SendLink ($email: String!){
        sendUserLink(email: $email)
    }
`
