import React, {useCallback, useRef, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Typography,
    TableCell,
    TableRow,
    TableHead, Table, TableContainer, TableBody
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useQuery} from "@apollo/client";
import {GET_LEARNER_EVENTS, LEARNER_IDS} from "./LearnerSchedule.gql";
import {
    GetLearnerEventsQuery,
    GetLearnerEventsQueryVariables,
    LearnerIdsQuery,
    LearnerIdsQueryVariables
} from "../../../generated/graphql";
import moment from "moment";

export const LearnerSchedule = () => {
    const nowRef = useRef(new Date());
    const {data: userData} = useQuery<LearnerIdsQuery, LearnerIdsQueryVariables>(LEARNER_IDS);
    const {data: oldEventsData} = useQuery<GetLearnerEventsQuery, GetLearnerEventsQueryVariables>(GET_LEARNER_EVENTS, {variables: {startTo: nowRef.current}});
    const {data: newEventsData} = useQuery<GetLearnerEventsQuery, GetLearnerEventsQueryVariables>(GET_LEARNER_EVENTS, {variables: {startFrom: nowRef.current}});

    const [expanded, setExpanded] = useState<string>('plan');

    const handleChange = useCallback((panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : '')
    }, []);
    const learnerIds = userData?.userInfo?.learners?.map(l => l.id?.split(' ')?.[0]);
    return (
        <Container style={{marginTop: 30}}>
            <Typography variant='h4'>Расписание</Typography>
            <Accordion expanded={expanded === "plan"} onChange={handleChange('plan')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>Предстоящие занятия <b>{newEventsData?.events.length}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата и время занятия</TableCell>
                                    <TableCell>Время окончания занятия</TableCell>
                                    <TableCell>Ученик(и)</TableCell>
                                    <TableCell>Предмет</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newEventsData?.events.length === 0 ? (
                                    <Typography>
                                        Пока занятия не запланированы
                                    </Typography>
                                ) : newEventsData?.events.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {moment(row.start).format('DD.MM.YY HH:mm')}
                                        </TableCell>
                                        <TableCell>{moment(row.end).format('HH:mm')}</TableCell>
                                        <TableCell>{learnerIds && row.learner?.filter(l => learnerIds.includes(l.id))?.map(l=> l.name).join(', ')}</TableCell>
                                        <TableCell>{row.lesson?.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "history"} onChange={handleChange('history')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>Прошедшие занятия <b>{oldEventsData?.events.length}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата и время занятия</TableCell>
                                    <TableCell>Время окончания занятия</TableCell>
                                    <TableCell>Ученик(и)</TableCell>
                                    <TableCell>Предмет</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {oldEventsData?.events.length === 0 ? (
                                    <Typography>
                                        Здесь скоро появятся прошедшие занятия
                                    </Typography>
                                ) : oldEventsData?.events.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {moment(row.start).format('DD.MM.YYY HH:mm')}
                                        </TableCell>
                                        <TableCell>{moment(row.start).format('HH:mm')}</TableCell>
                                        <TableCell>{learnerIds && row.learner?.filter(l => learnerIds.includes(l.id))?.map(l=> l.name).join(', ')}</TableCell>
                                        <TableCell>{row.lesson?.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}
