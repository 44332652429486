import React, {useState} from "react";
import {Button, Card, CardActions, CardContent, TextField} from "@mui/material";
import {useMutation} from "@apollo/client";
import {LOGIN_MUTATION} from "./Login.gql";
import {LoginMutation, LoginMutationVariables} from "../../generated/graphql";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const navigate = useNavigate();
    const [loginMutation] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const saveValue = (saveFunc: (v: string) => void) => (e:any) => saveFunc(e.target.value);
    const enter = async (e: any) => {
        e.preventDefault();
        try {
            const {data} = await loginMutation({variables: {email, password}});
            if(data?.loginWithEmail) {
                const token = data?.loginWithEmail?.accessToken;
                const refreshToken = data?.loginWithEmail?.refreshToken;
                if(!token || !refreshToken) {
                    console.error('token is empty')
                    return;
                }
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                navigate('/lk')
            }

        } catch (e) {
            alert('Неверный логин и/или пароль');
        }
    }
    return(
        <Card variant="outlined">
            <form onSubmit={enter}>
                <CardContent>
                    <p>
                        <TextField label="Логин" onChange={saveValue(setEmail)}/>
                    </p>
                    <p>
                        <TextField label="Пароль" type='password' onChange={saveValue(setPassword)}/>
                    </p>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" type='submit'>Войти</Button>
                </CardActions>
            </form>
        </Card>
    );
}
