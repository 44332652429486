import {gql} from "@apollo/client";

export const PROFILE_QUERY = gql`
    query Profile {
        userInfo {
            id
            firstName
            lastName
            email
            learners {
                learner
                level
                purpose
            }
        }
    }
`

export const PROFILE_SAVE = gql`
    mutation SaveProfile ($profile: ProfileInfoInput!) {
        updateProfile(input: $profile)
    }
`
