import {gql} from "@apollo/client";

export const GET_LEARNER_EVENTS = gql`
    query getLearnerEvents($startFrom: DateTime, $startTo: DateTime) {
        events(startFrom: $startFrom, startTo: $startTo) {
            id
            start
            end
            lesson {
                id
                name
            }
            learner {
                id
                name
            }
        }
    }
`

export const LEARNER_IDS = gql`
    query learnerIds {
        userInfo {
            learners {
                id
            }
        }
    }
`
