import { gql } from "@apollo/client";

export const REVIEWS_QUERY = gql`
    query Reviews {
        reviews {
            id
            name
            text
        }
    }
`
