import React, {useEffect} from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {GET_LESSONS, SAVE_LESSONS} from "./Subjects.gql";
import {useMutation, useQuery} from "@apollo/client";
import {
    GetLessonsQuery,
    GetLessonsQueryVariables,
    SaveLessonsMutation,
    SaveLessonsMutationVariables
} from "../../../generated/graphql";

export const Subjects = () => {
    const voidLesson: {
        id?: number,
        tempId?: number,
        name: string,
        price: number
    } = {
        name: '',
        price: 0,
        tempId: Date.now(),
    }

    const {data} = useQuery<GetLessonsQuery, GetLessonsQueryVariables>(GET_LESSONS);
    const [saveLessons] = useMutation<SaveLessonsMutation, SaveLessonsMutationVariables>(SAVE_LESSONS);

    const lessons = data?.lessons;

    const formik = useFormik({
        initialValues: {
            lessons: [{...voidLesson}],
        },
        onSubmit: async (values) => {
            try {
                await saveLessons({
                    variables: {
                        lessons: values.lessons?.map(l => ({
                            id: l?.id,
                            name: l.name,
                            price: +l.price
                        }))
                    }
                })
                alert('Сохранено');
            } catch (e) {
                alert('Ошибка! =(');
            }
        },
    });

    useEffect(() => {
        if (lessons) {
            formik.setValues({lessons: lessons?.map(l => ({id: l.id, name: l?.name ?? '', price: l.price ?? 0}))});
        }
    }, [lessons])

    const addLesson = () => {
        formik.setFieldValue('lessons', [...formik.values.lessons, {...voidLesson, tempId: Date.now()}]);
    }

    return (
        <div>
            <Typography variant='h4'>Предметы</Typography>
            <Button size="small" onClick={addLesson} type='button'>Добавить</Button>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    {formik.values.lessons.map((v, index) => (
                        <Grid container spacing={3} key={v.tempId || v.id}>
                            <Grid item xs>
                                <TextField
                                    label="Название предмета" {...formik.getFieldProps(`lessons[${index}].name`)}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs>
                                <TextField label="Цена" {...formik.getFieldProps(`lessons[${index}].price`)}
                                           type='number' fullWidth/>
                            </Grid>
                        </Grid>

                    ))}
                    <Button size="small" color="primary" type='submit'>Сохранить</Button>
                </div>
            </form>

        </div>
    );
}
