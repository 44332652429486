import {gql} from "@apollo/client";

export const GET_LESSONS = gql`
    query GetLessons {
        lessons {
            id
            name
            price
        }
    }
`

export const SAVE_LESSONS = gql`
    mutation SaveLessons($lessons: [LessonsSaveInput!]!) {
        updateLessons(lessons: $lessons) {
            id
            name
            price
        }
    }
`
