import {gql} from "@apollo/client";
export const UPDATE_EVENTS = gql`
    mutation UpdateEvent($event: EventsUpdateInput!, $eventId: Int!, $isMulti: Boolean!) {
        updateEvent(event: $event, eventId: $eventId, isMulti: $isMulti){
            id
            title
            start
            end
            freeTimeDuration
            learner {
                id
                name
            }
            lesson {
                id
                name
            }
            duration
            cost
        }
    }
`
