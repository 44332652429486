import React from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography,
    DialogContentText,
    TextField,
    DialogActions,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Face as FaceIcon} from "@mui/icons-material";
import {useMutation, useQuery} from "@apollo/client";
import {ADD_LEARNER, GET_LEARNERS, SEND_LINK} from "./Learners.gql";
import {
    AddLearnersMutation,
    AddLearnersMutationVariables,
    LearnersQuery
} from "../../../generated/graphql";
import {useFormik} from "formik";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 752,
        },
        demo: {
            backgroundColor: theme.palette.background.paper,
        },
        item: {
            ['&:hover']: {
                backgroundColor: '#03a9f452',
            }
        },
        title: {
            margin: theme.spacing(4, 0, 2),
        },
    }),
);

export const Learners = () => {
    const classes = useStyles();
    const {data} = useQuery<LearnersQuery>(GET_LEARNERS)
    const [createUser] = useMutation<AddLearnersMutation, AddLearnersMutationVariables>(ADD_LEARNER);
    const [sendLink] = useMutation<AddLearnersMutation, AddLearnersMutationVariables>(SEND_LINK);
    const [openAddWindow, setOpenAddWindow] = React.useState(false);

    const handleClickOpenAddUser = () => {
        setOpenAddWindow(true);
    };

    const handleCloseAddUser = () => {
        setOpenAddWindow(false);
    };

    const handleSendLink = (email: string) => () =>  {
        sendLink({variables: {email}});
    };

    const formik = useFormik<{email: string, name: string}>({
        initialValues: {
            email:'',
            name: '',
        },
        onSubmit: async (values) => {
            try {
                await createUser({variables: values});
                alert('Сохранено');
            } catch (e) {
                alert('Ошибка! =(');
            }
        },
    });

    return (
        <div>
            <Typography variant="h6" className={classes.title}>
                Ученики
            </Typography>
            <div className={classes.demo}>
                <List>
                    <Button onClick={handleClickOpenAddUser} color="primary" >Добавить ученика</Button>
                    {data?.learners.map((user) => (
                        <ListItem className={classes.item} key={user?.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FaceIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={user.learner}
                                secondary={`${user.firstName} ${user.lastName}`}
                            />
                            <ListItemSecondaryAction>
                                <Button onClick={user.email ? handleSendLink(user.email) : undefined}>
                                    Выслать ссылку для доступа к аккаунту
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <Dialog open={openAddWindow} onClose={handleCloseAddUser} aria-labelledby="form-dialog-title">
                    <form onSubmit={formik.handleSubmit}>
                        <DialogTitle id="form-dialog-title">Добавление пользователя</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Введите email совершеннолетнего представителя и имя ученика
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Email Address"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                fullWidth
                            />
                            <TextField
                                margin="dense"
                                name="name"
                                label="Ученик"
                                onChange={formik.handleChange}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAddUser} color="secondary">
                                Отмена
                            </Button>
                            <Button color="primary" type="submit">
                                Добавить
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
}
