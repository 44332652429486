import {
    AppBar,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import {Link as BaseLink, Route, Routes, useLocation} from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FaceIcon from "@mui/icons-material/Face";
import MenuIcon from "@mui/icons-material/Menu";
import {Profile} from "../learner/Profile/Profile";
import {logout} from "../../core/client";
import {LearnerSchedule} from "../learner/LearnerSchedule/LearnerSchedule";

const LeftMenu = () => {
    let location = useLocation();
    return (
        <div>
            <List>
                <ListItem component={BaseLink} to={location.pathname}>
                    <ListItemIcon><EventNoteIcon/></ListItemIcon>
                    <ListItemText primary='Занятия' />
                </ListItem>
                <ListItem component={BaseLink} to={`${location.pathname}/profile`}>
                    <ListItemIcon><FaceIcon/></ListItemIcon>
                    <ListItemText primary='Профиль' />
                </ListItem>
            </List>
            <Divider />
            <ListItem onClick={() => logout()}>
                <Button> Выход </Button>
            </ListItem>
        </div>
    )
}


export const Learner = () => {

    const [openMenu, setMenuOpen] = React.useState(false);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    let { pathname } = useLocation();

    return <>
        <AppBar position="static">
            <Toolbar style={{justifyContent: 'space-between'}}>
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuOpen}
                    edge="start"
                    size="large">
                    <MenuIcon/>
                </IconButton>

                <Typography variant="h6">
                    Личный кабинет ученика
                </Typography>
                <div/>
            </Toolbar>
        </AppBar>
        <div>
            <Container>
                <Routes>
                    <Route path={pathname} element={<LearnerSchedule/>}/>
                    <Route path={`${pathname}/profile`} element={<Profile/>}/>
                </Routes>
            </Container>
        </div>
        <Drawer open={openMenu} onClose={handleMenuClose}>
            <LeftMenu/>
        </Drawer>
    </>;
}
