import {gql} from "@apollo/client";

export const ACCESS_TIME_INFO = gql`
    fragment AccessTimeInfo on AccessTime {
        id
        weekday
        from
        to
    }
`

export const GET_ACCESS_TIME = gql`
    query AccessTime {
        accessTime {
            ...AccessTimeInfo
        }
    }
    ${ACCESS_TIME_INFO}
`

export const SAVE_ACCESS_TIME = gql`
    mutation SaveAccessTime($accessTime: [AccessTimeInput!]!) {
        accessTimeSave(accessTime: $accessTime) {
            ...AccessTimeInfo
        }
    }
    ${ACCESS_TIME_INFO}
`
