import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {Calendar, momentLocalizer, View} from "react-big-calendar";
import moment from "moment";
import React, {useCallback, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GET_EVENTS, REMOVE_EVENTS, UPDATE_EVENTS} from "./Schedule.gql";
import {
    CalendarEvent,
    GetCalendarEventsQuery,
    GetCalendarEventsQueryVariables,
    RemoveEventsMutation,
    RemoveEventsMutationVariables,
    UpdateEventsMutation,
    UpdateEventsMutationVariables
} from "../../../generated/graphql";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {UpdateLessonForm} from "../../../components/UpdateLessonForm/UpdateLessonForm";
import makeStyles from '@mui/styles/makeStyles';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop<CalendarEvent>(Calendar);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#cfffdb',
            padding: '0 15px',
            margin: '15px 0',
        },
    }),
);
export const Schedule = () => {
    const classes = useStyles();
    const {data} = useQuery<GetCalendarEventsQuery, GetCalendarEventsQueryVariables>(GET_EVENTS);
    const events = data?.events.map((e) => ({
        id: e.id,
        title: e.title,
        start: new Date(e.start),
        end: new Date(e.end),
        cost: e.cost,
        freeTimeDuration:e.freeTimeDuration,
        learnerName: e.learner?.[0]?.name,
        lessonName: e.lesson?.name,
    }))
    const [viewState, setView] = useState<[View, Date | null]>(['week', null]);
    const [confirmInfo, setConfirmInfo] = useState<{start: Date, end: Date, event: CalendarEvent} | null>(null);
    const [detailInfo, setDetailInfo] = useState<any | null>(null);
    const [actionsInfo, setActionsInfo] = useState<CalendarEvent | null>(null);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const [removeEvents] = useMutation<RemoveEventsMutation,RemoveEventsMutationVariables>(REMOVE_EVENTS, {refetchQueries: ['getCalendarEvents']})
    const confirmRemove = useCallback((isMulti: boolean) => () => {
        setRemoveId(null);
        return removeEvents({variables: {eventId: removeId || 0, isMulti}})
    }, [removeId, removeEvents])
    const changeEvent = useCallback(({start, end, event}: any) => {
        setConfirmInfo({start, end, event})
    }, []);
    const [updateEvents, {loading: pending}] = useMutation<UpdateEventsMutation,UpdateEventsMutationVariables>(UPDATE_EVENTS, {refetchQueries: ['getCalendarEvents']})
    const confirmUpdate = useCallback((isMulti: boolean) => (confirmInfo ? async () => {
        await updateEvents({variables: {
                eventId: confirmInfo.event.id,
                event: {
                    start: confirmInfo.start,
                    end: confirmInfo.end,
                },
                isMulti
            }})
        setConfirmInfo(null);
    } : undefined), [confirmInfo, updateEvents])

    return (
        <div>
            <Typography variant='h4'>Расписание</Typography>
            {actionsInfo && <div className={classes.actions}>
                <div><b>[{actionsInfo.title}]</b> Действия: </div>
                <div>
                    <Link
                        color='primary'
                        target="_blank"
                        href={`/lk/material/${actionsInfo.id}`}
                        underline="hover">Материалы</Link>
                    <Button color='primary' onClick={() => {
                        setDetailInfo(actionsInfo);
                        setActionsInfo(null);
                    }}>Редактировать</Button>
                    <Button color='secondary' onClick={() => {
                        setRemoveId(actionsInfo.id);
                        setActionsInfo(null);
                    }}>Удалить</Button>
                </div>
            </div>}
            {events && <DnDCalendar
                localizer={localizer}
                showMultiDayTimes
                events={events}
                style={{height: '80vh'}}
                onDoubleClickEvent={(event) => {setDetailInfo(event)}}
                onDrillDown={(date, view) => setView([view, date])}
                onNavigate={ console.log.bind(null, 'onNavigate')}
                onRangeChange={(range, view) => {
                    console.log('onRangeChange', range, view);
                    let date = viewState[1];
                    if (Array.isArray(range) && range?.[0]) {
                        date = range?.[0];
                    }
                    if (range && 'start' in range && 'end' in range) {
                        date = moment(range.start).add(moment(range.end).diff(range.start, 'days')/2, "days").toDate();
                    }
                    console.log(date);
                    setView([view ?? viewState[0], date]);
                }}
                onSelectEvent={(calendarEventData, nativeEvent) => {
                    console.log('onSelectEvent', calendarEventData, nativeEvent);
                    setActionsInfo(calendarEventData);
                }}
                onSelecting={console.log.bind(null, 'onSelecting') && undefined}
                onShowMore={console.log.bind(null, 'onShowMore')}
                onSelectSlot={console.log.bind(null, 'onSelectSlot')}
                onView={console.log.bind(null, 'onView')}
                view={viewState[0]}
                scrollToTime={viewState[1] ?? undefined}
                date={viewState[1] ?? undefined}
                onEventDrop={changeEvent}
                onEventResize={changeEvent}
            />}
            <Dialog
                open={!!confirmInfo}
                onClose={() => setConfirmInfo(null)}
            >
                <DialogTitle> Изменение события </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>Начало
                            занятия: {moment(confirmInfo?.event.start).format('DD.MM.YYYY HH:mm')} -&gt; {moment(confirmInfo?.start).format('DD.MM.YYYY HH:mm')}</p>
                        <p>Конец
                            занятия: {moment(confirmInfo?.event.end).format('DD.MM.YYYY HH:mm')} -&gt; {moment(confirmInfo?.end).format('DD.MM.YYYY HH:mm')}</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmInfo(null)} disabled={pending}>
                        Отменить
                    </Button>
                    <Button onClick={confirmUpdate(true)} color="secondary" disabled={pending}>
                        Изменить все последующие
                    </Button>
                    <Button autoFocus onClick={confirmUpdate(false)} color="primary" disabled={pending}>
                        Изменить только это занятие
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={!!detailInfo}
                onClose={() => setDetailInfo(null)}
            >
                <UpdateLessonForm initValues={detailInfo as any} close={() => setDetailInfo(null)} />
            </Dialog>
            <Dialog open={!!removeId} onClose={() => setRemoveId(null)}>
                <DialogTitle>Удаление события</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Что удаляем?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRemoveId(null)}>
                        Отменить
                    </Button>
                    <Button onClick={confirmRemove(true)} color="secondary">
                        Удалить все последующие копии события
                    </Button>
                    <Button autoFocus onClick={confirmRemove(false)} color="primary">
                        Удалить только это событие
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
