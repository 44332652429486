import {useQuery} from "@apollo/client";
import {LK_QUERY} from "./Lk.gql";
import {LkQuery} from "../../generated/graphql";
import {Teacher} from "./Teacher";
import {Learner} from "./Learner";
import React from "react";
import {useNavigate} from "react-router-dom";

export const Lk = () => {
    const {data, loading} = useQuery<LkQuery>(LK_QUERY);
    const navigate = useNavigate();
    if(!data) {
        navigate('/', {replace: true})
        return null;
    }
    return loading? <div>Загрузка...</div> : data.userInfo.roles?.includes('ADMIN') ? (
        <Teacher />
    ) : <Learner />
}
