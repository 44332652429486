import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Tooltip
} from "@mui/material";
import {useMutation, useQuery} from "@apollo/client";
import {PROFILE_QUERY, PROFILE_SAVE} from "./Profile.gql";
import {
    ProfileQuery,
    ProfileQueryVariables,
    SaveProfileMutation,
    SaveProfileMutationVariables
} from "../../../generated/graphql";
import { Alert } from '@mui/material';

export const Profile = () => {
    const [saveProfile] = useMutation<SaveProfileMutation, SaveProfileMutationVariables>(PROFILE_SAVE);
    const [savedSuccessfulMessage, setSavedSuccessfulMessage] = useState<boolean>();
    const handleCloseSuccessfulMessage = () => {
        setSavedSuccessfulMessage(false);
    };
    const formik = useFormik({
        initialValues: {
            isNameMatches: false,
            firstName: '',
            lastName: '',
            learner: '',
            level: '',
            purpose: '',
        },
        validate(values) {
            const errors: { [field: string]: string } = {};
            if (!values.level) {
                errors.level = 'В каком классе сейчас ученик?'
            }
            return errors;
        },
        onSubmit(v) {
            return saveProfile({
                variables: {
                    profile: {
                        learners: [{
                            name: v.learner,
                            level: v.level,
                            purpose: v.purpose,
                        }],
                        firstName: v.firstName,
                        lastName: v.lastName,
                    }
                }
            }).then(() => setSavedSuccessfulMessage(true))
        }
    });

    const {data} = useQuery<ProfileQuery, ProfileQueryVariables>(PROFILE_QUERY);
    const userInfo = data?.userInfo;

    useEffect(() => {
        if (userInfo) {
            formik.setValues({
                firstName: userInfo.firstName ?? '',
                lastName: userInfo.lastName ?? '',
                learner: userInfo.learners?.[0]?.learner ?? '',
                level: userInfo.learners?.[0]?.level ?? '',
                purpose: userInfo.learners?.[0]?.purpose ?? '',
                isNameMatches: `${userInfo.lastName} ${userInfo.firstName}` === userInfo.learners?.[0]?.learner ?? false
            })
        }
    }, [userInfo])

    const learnerName = `${formik.values.lastName} ${formik.values.firstName}`;

    useEffect(() => {
        if (formik.values.isNameMatches) {
            formik.setFieldValue('learner', learnerName)
        }
    }, [formik.values.isNameMatches, learnerName])

    return (
        <form onSubmit={formik.handleSubmit}>
            <Snackbar open={savedSuccessfulMessage} autoHideDuration={6000} onClose={handleCloseSuccessfulMessage}>
                <Alert onClose={handleCloseSuccessfulMessage} severity="success">
                    Профиль сохранён!
                </Alert>
            </Snackbar>
            <Box m={1} maxWidth={400}>
                <h2>Профиль</h2>
                <div>
                    <Tooltip title={'Пока редактировать email нельзя'}>
                        <TextField
                            margin="dense"
                            label="Email"
                            value={userInfo?.email ?? ''}
                            disabled
                            fullWidth
                            InputLabelProps={{shrink: true}}
                        />
                    </Tooltip>
                </div>
                <div>
                    <TextField margin="dense" label="Фамилия" {...formik.getFieldProps('lastName')} fullWidth/>
                </div>
                <div>
                    <TextField margin="dense" label="Имя" {...formik.getFieldProps('firstName')} fullWidth/>
                </div>
                <div>
                    <TextField
                        margin="dense"
                        label="Имя ученика"
                        {...formik.getFieldProps('learner')}
                        InputLabelProps={{shrink: !!formik.values.learner}}
                        disabled={formik.values.isNameMatches} fullWidth
                    />
                    <br/>
                    <FormControlLabel
                        control={<Checkbox checked={formik.values.isNameMatches} size={"small"}
                                           onChange={formik.handleChange} name="isNameMatches"/>}
                        label="Соответствует аккаунту"
                    />
                    <Button>Добавить ученика</Button>
                </div>
                <div>
                    <FormControl
                        fullWidth
                        error={!!formik.errors.level}
                        margin='dense'
                    >
                        <InputLabel>Уровень&nbsp;образования</InputLabel>
                        <Select
                            value={formik.values.level}
                            name='level'
                            onChange={formik.handleChange}
                            fullWidth
                        >
                            <MenuItem value={'Дошкольник'}>Дошкольник</MenuItem>
                            {new Array(11).fill('').map((v, index) => (
                                <MenuItem value={`${index + 1} класс`} key={index}>{`${index + 1} класс`}</MenuItem>
                            ))}
                            <MenuItem value={'Выпусник прошлых лет'}>Выпусник прошлых лет</MenuItem>
                        </Select>
                        {!!formik.errors.level && (<FormHelperText>{formik.errors.level}</FormHelperText>)}
                    </FormControl>
                </div>
                <div>
                    <TextField
                        margin='dense'
                        label="Цель занятий"
                        multiline
                        rows={4}
                        {...formik.getFieldProps('purpose')}
                        fullWidth
                        variant='outlined'
                    />
                </div>
                <Box marginTop={2} textAlign="right">
                    <Button variant='outlined' type='submit'>Сохранить</Button>
                </Box>
            </Box>
        </form>
    )
}
