import {gql} from "@apollo/client";

export const CREATE_LESSON_INFO = gql`
    query CreateLessonInfo {
        learners {
            id
            learner
            phone
        }
        lessons {
            id
            price
            name
        }
    }
`

export const CREATE_EVENTS = gql`
    mutation CreateEvents($event: EventsCreateInput!) {
        createEvents(event: $event){
            id
            title
            start
            end
        }
    }
`
