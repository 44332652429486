import {Button, Card, Typography, CardContent, CardActions, TextField, Container} from "@mui/material";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {useMutation} from "@apollo/client";
import {RESET_PASSWORD} from "./ResetPassword.gql";
import {ResetPasswordMutation, ResetPasswordMutationVariables} from "../../generated/graphql";
import {useFormik} from "formik";
import { useParams, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    pos: {
        marginBottom: 12,
    },
});

export const ResetPassword  = () => {
    const classes = useStyles();
    const [resetPassword] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(RESET_PASSWORD);
    const {token = ''} = useParams<{token: string}>();
    const navigate = useNavigate();
    const formik = useFormik<{ email: string, password: string, confirm: string }>({
        initialValues: {
            email:'',
            password: '',
            confirm: '',
        },
        onSubmit: async (values) => {
            try {
                await resetPassword({variables: {
                    input: {
                        token,
                        email: values.email,
                        password: values.password
                    }
                }});
                alert('Сохранено');
                navigate('/');
            } catch (e) {
                alert('Ошибка! =(');
            }
        },
    });

    const error = formik.values.password!==formik.values.confirm;
    return (
        <Container maxWidth='xs'>
            <Card>
                <form onSubmit={formik.handleSubmit}>
                    <CardContent>
                        <Typography  className={classes.pos} variant="h5" component="h2">
                            Получение доступа к учётной записи
                        </Typography>
                        <TextField type='email' name='email' onChange={formik.handleChange} label="Подтвердите email" fullWidth required/>
                        <TextField type='password' name='password' onChange={formik.handleChange}  label="Введите новый пароль" fullWidth required />
                        <TextField type='password' name='confirm' onChange={formik.handleChange}  label="Подтвердите новый пароль" fullWidth required />
                        {error && (
                            <Typography component="p" color='error'>
                                Пароли не совпадают
                            </Typography>
                        )}

                    </CardContent>
                    <CardActions>
                        <Button size="small" type='submit' disabled={error}>Сохранить</Button>
                    </CardActions>
                </form>
            </Card>
        </Container>
    )
}
