import {gql} from "@apollo/client";

export const NEW_TOKEN = gql`
    mutation NewToken($token: String!) {
        useRefreshToken(token: $token) {
            accessToken
            refreshToken
        }
    }
`
