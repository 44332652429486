import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
    mutation Login($email: String!, $password:  String!) {
        loginWithEmail(email: $email, password: $password) {
            accessToken
            refreshToken
        }
    }
`
