import {gql} from "@apollo/client";

export const GET_EVENTS = gql`
    query getCalendarEvents {
        events {
            id
            title
            learner {
                id
                name
            }
            lesson {
                id
                name
            }
            start
            end
            freeTimeDuration
            cost
        }
    }
`

export const UPDATE_EVENTS = gql`
    mutation updateEvents($event: EventsUpdateInput!, $eventId: Int!, $isMulti: Boolean = false ) {
        updateEvent(event: $event, isMulti: $isMulti, eventId: $eventId){
            id
            start
            end
            title
        }
    }
`

export const REMOVE_EVENTS = gql`
    mutation removeEvents($eventId: Int!, $isMulti: Boolean = false ) {
        removeEvent(isMulti: $isMulti, eventId: $eventId)
    }
`
